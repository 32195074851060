/* .side_bar_section > .btn img {
    filter: invert(1) !important;
}

.search_inner.open {
    height: 140px !important;
}

.btn.bonus {
    background-color: rgb(104 162 0) !important;
}

.footer-wrapper {
    background-color: rgb(104 162 0/40%) !important;
}

.footer-imgs img {
    filter: grayscale(1) drop-shadow(2px 4px 6px black) !important;
}

.jackpot_wrapper {
    background: rgb(40 61 2) !important;
    border-image: linear-gradient(0.25turn, #161816, #adb4b3, #161816) 2 0 2 0 !important;
} */
.header-logo {
    height: 65%;
}
.footer-section.images{
    border-image: linear-gradient(0.25turn, #13122a, rgba(193, 150, 108, 0.5), #13122a) 1 0 1 0;
}
.bonus_slider:before {
    background-size: cover;
    opacity: 1;

}
.bonus_wrapper {
    justify-content: end;
}
.search_icon{
    @include recolor(#d9ac61)
}
.btn.bonus {
    right: 7vw;
    bottom: auto;
    top: auto;
    height: 70%;
    background-color: #ffc141;
}

@media screen and (max-width: 700px) {
    .bonus_wrapper {
        justify-content: center;
        .bonus_pic{
            margin-bottom: 20px;
        }
    }
}
@media screen and (max-width: 600px) {
    .btn.bonus {
        right: auto;
        bottom: 30px;
        top: auto;
        height: initial;

    }
    .bonus_slider:before {
       background-position-x: -27em;
    }
    .bonus_wrapper{
        img{
            position: absolute;
            right: initial !important;
            top: 10%;
            height: 25%;
        }
    }
}
